/*----------------------------------------*/
/*  04. FEATURES CSS START
/*----------------------------------------*/

.features {
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        gap: 15px;

        @media #{$xs} {
            flex-direction: column;
			gap: 0px;
        }
    }

    &__item {
        flex: 0 1 24%;
        position: relative;
        padding: 30px;
        background: $white;
        border-bottom: 1px solid $grey-4;
        @include transition(.3s);

        &:hover {
            @include box-shadow(0px 0px 50px 0px rgba(0, 0, 0, 0.08));
            border-color: $white;
            z-index: 99;

            & .features__icon {
                & i {
                    -webkit-transform: translate3d(0, -10px, 0);
                    -moz-transform: translate3d(0, -10px, 0);
                    -ms-transform: translate3d(0, -10px, 0);
                    -o-transform: translate3d(0, -10px, 0);
                    transform: translate3d(0, -10px, 0);
                }
            }
        }
    }

    &__content {
        position: relative;

        & h3 {
            font-family: $fontBody;
            font-size: 16px;
            font-weight: 600;
            margin-bottom: 0px;
        }
    }

    &__icon {
        margin-bottom: 20px;

        & i {
            font-size: 26px;
            color: $heading-color;
            text-align: center;
            display: inline-block;
            @include border-radius(50px);
            backface-visibility: hidden;
            -webkit-transform: translate3d(0, 0, 0);
            -moz-transform: translate3d(0, 0, 0);
            -ms-transform: translate3d(0, 0, 0);
            -o-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
            -webkit-transition: transform .3s cubic-bezier(.21, .6, .44, 2.18);
            -moz-transition: transform .3s cubic-bezier(.21, .6, .44, 2.18);
            -ms-transition: transform .3s cubic-bezier(.21, .6, .44, 2.18);
            -o-transition: transform .3s cubic-bezier(.21, .6, .44, 2.18);
            transition: transform .3s cubic-bezier(.21, .6, .44, 2.18);
        }
    }
}