/*----------------------------------------*/
/*  03. SLIDER CSS START
/*----------------------------------------*/

.single-slider {
    position: relative;
    @include background();
}

.slider {
    &__area {
        position: relative;
    }

    &__height {
        min-height: 800px;

        @media #{$sm} {
            min-height: 700px;
        }

        @media #{$xs} {
            min-height: 600px;
        }
    }

    &__content {
        position: relative;
        z-index: 1;

        & h1 {
            font-size: 70px;
            font-weight: 700;
            margin-bottom: 25px;

            @media #{$md} {
                font-size: 50px;
            }

            @media #{$sm} {
                font-size: 50px;
            }

            @media #{$xs} {
                font-size: 40px;
                margin-bottom: 15px;
            }
        }

        & p {
            color: $heading-color;
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 40px;

            @media #{$xs} {
                margin-bottom: 25px;
            }
        }
    }
}

@media only screen and (max-width: 1700px) {
    .slider__height {
        min-height: 600px;
    }
}