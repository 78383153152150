/*----------------------------------------*/
/*  10. BRAND CSS START
/*----------------------------------------*/

.brand {
  &__subtitle {
    & p {
      font-size: 16px;
      padding-right: 120px;
      margin-top: 30px;
      line-height: 30px;
    }
  }

  &__item {
    text-align: center;
    margin: 0px 5px;
    border: 1px solid $grey-4;
    @include border-radius(10px);
    overflow: hidden;

    & img {
      width: inherit !important;
      display: inline-block !important;
    }
  }

}