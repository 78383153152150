/*----------------------------------------*/
/*  06. CAPABILITIES CSS START
/*----------------------------------------*/

.capabilities {
    &__content {
        @media #{$md} {
            position: relative;
        }

        @media #{$sm} {
            position: relative;
        }

        @media #{$xs} {
            position: relative;
            padding: 0px 20px;
        }
    }

    &__thumb {
        right: 0;
        top: 0;
        width: 50%;
        height: 100%;
        @include background();

        @media #{$md} {
            display: none;
        }

        @media #{$sm} {
            display: none;
        }

        @media #{$xs} {
            position: relative;
            width: 100%;
            height: 230px;
            margin-bottom: 40px;
            margin-top: -60px;
        }
    }

    &__shape {
        left: 0;
        top: -165px;
    }

    &__list {
        & ol {
            & li {
                list-style: inherit;
                margin-left: 22px;
                margin-bottom: 25px;
                font-size: 20px;
                font-weight: 500;
                line-height: 24px;
                color: $heading-color;

                &:hover {
                    color: $theme-color-2;
                }

                &:last-child {
                    margin-bottom: 0px;
                }
            }
        }

        & p {
            margin-bottom: 0px;
        }
    }
}