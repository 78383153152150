/*----------------------------------------*/
/*  15. TESTIMONIAL CSS START
/*----------------------------------------*/

.testimonial {
    &__thumb {

        @media #{$xs} {
            padding-top: 50px;
        }
    }

    &__content {
        margin-bottom: 0px;

        @media #{$xs} {
            margin-bottom: 0px;
        }
    }

    &__item {
        & p {
            font-size: 24px;
            line-height: 34px;
            font-family: $fontHead;
            margin-bottom: 25px;
        }
    }

    &__info {
        & h4 {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 0;
        }

        & span {
            font-size: 14px;
        }
    }

    &__slider {
        & .slick-dots {
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            width: auto;

            & li {
                width: 14px;
                height: 14px;
                background: transparent;
                border: 2px solid rgba($color: $white, $alpha: .4);
                @include border-radius(50%);
                margin-right: 10px;
                @include transition(.3s);

                & button::before {
                    display: none;
                }

                &.slick-active {
                    background: $white;
                    border-color: $white;
                }
            }
        }
    }
}